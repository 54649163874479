import React from 'react';
import './style.scss';

const Footer = () => (
    <footer className="footer">
        <div className="content has-text-centered">
            <p>@MecyDev</p>
        </div>
    </footer>
);

export default Footer;

