import React from 'react';
import { FaLinkedin, FaGithubSquare, FaTwitterSquare, FaYoutubeSquare, FaFacebookSquare } from 'react-icons/fa';
import { useStaticQuery, graphql } from 'gatsby';

export default () => {
	const data = useStaticQuery(
		graphql`
			query {
	  			site {
					siteMetadata {
						twitter
						github
						linkedin
						youtube
						facebook
					}
	  			}
			}
		`
	)
	return (
		<nav className="navbar is-fixed-bottom" role="navigation" aria-label="main navigation">
			<div className="container">
				<div className="navbar-brand">
					<a className="navbar-item" href={data.site.siteMetadata.github}>
						<span className="icon">
							<i><FaGithubSquare /></i>
						</span>
					</a>
					<a className="navbar-item" href={data.site.siteMetadata.youtube}>
						<span className="icon">
							<i><FaYoutubeSquare /></i>
						</span>
					</a>
					<a className="navbar-item" href={data.site.siteMetadata.linkedin}>
						<span className="icon">
							<i><FaLinkedin /></i>
						</span>
					</a>
					<a className="navbar-item" href={data.site.siteMetadata.twitter}>
						<span className="icon">
							<i><FaTwitterSquare /></i>
						</span>
					</a>
					<a className="navbar-item" href={data.site.siteMetadata.facebook}>
						<span className="icon">
							<i><FaFacebookSquare /></i>
						</span>
					</a>
				</div>
			</div >
		</nav >
	)
}