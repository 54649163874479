import React from 'react';
import { IconContext } from 'react-icons';
import './style.scss';
import Helmet from './helmet';
import Header from './header';
import Midsection from './midsection';
import Footer from './footer';
import Navbar from './navbar';

const Layout = ({ children }) => (
	<div>
		<Helmet />
		<IconContext.Provider value={{ className: 'react-icons' }}>
			<Header />
			<Midsection children={children} />
			<Footer />
			<Navbar />

		</IconContext.Provider>
	</div>
);

export default Layout;
