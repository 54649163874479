import React from 'react';
import { Link, graphql } from 'gatsby';
import Pagination from './pagination'

const Midsection = (props) => (
	<section className="section">
		<div className="container">
			<div className="columns">
				{props.children}
			</div>
		</div>
	</section>
);

export default Midsection;
