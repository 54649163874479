import React from 'react';
import { Link } from 'gatsby';
import Background from '../images/banblog.svg'
import Navbar from './navbar';

const Header = ({ siteTitle }) => (
	<section className="hero">
		<div className="hero-body">
			<Link to='/'>
				<img src={Background} alt="Bannière" />
			</Link>
		</div>
	</section>
);


export default Header;
